import React, { useState } from 'react';
import useStore from '../Base/Store';
import WebAPI from '../Base/WebAPI';
import { ifNullOrEmpty, isEmptyObjectArray, isNullOrEmpty, isNullOrUndefined, safeBool, safeInt, safeString } from '../Base/Utils';
import config from '../../config.json';
import { getNotificationCount } from '../Base/DataProvider';
import shallow from 'zustand/shallow';
import { INVOICE_COPY_HEADINGS, TAX_REG_COMPOSITION_SCHEME } from '../Base/Common';

const AuthContext = React.createContext({
    token: '',
    register: async (userData) => { },
    updateContextState: () => { },
    login: async (email, password) => { },
    logout: () => { },
    isLoggedIn: false,
    currentUser: {},
    currentSubscriber: {},
    defaultWarehouseId: 0,
    settings: {},
    updateSettings: (settings) => { },
    appModules: [{}],
    masterData: [{}],
    warehouseList: [{}],
    setWarehouseList: (warehouseList) => { },
    unitConversionList: [{}],
    updateUnitConversionList: (uom) => { },
    setSubscriberDetails: async (userSubscriberData) => { },
    moduleAccess: (appModuleId) => { },
    setSubscriberSessionData: (subscriberData) => { },
    customFields: [{}],
    refreshCustomFields: () => { },
    appVersion: ''
});

export const AuthContextProvider = (props) => {
    const [token, setToken] = useState(null);
    const [appVersion, setAppVersion] = useState('');
    const [currentUser, setCurrentUser] = useState({});
    const [currentSubscriber, setCurrentSubscriber] = useState({});
    const [warehouseList, setWarehouseList] = useState([]);
    const [customFields, setCustomFields] = useState([]);
    const [unitConversionList, setUnitConversionList] = useState([]);
    const [defaultWarehouseId, setDefaultWarehouseId] = useState(0);
    const [appModules, setAppModules] = useState([{}]);
    const [masterData, setMasterData] = useState([{}]);
    const [settings, setSettings] = useState({});
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const showAlert = useStore(state => state.showAlert);
    const closeAlert = useStore(state => state.closeAlert);
    const [notificationCount, setNotificationCount] = useStore(state => [state.notificationCount, state.setNotificationCount], shallow);
    const [appVersionCheck, setAppVersionCheck] = useStore(state => [state.appVersionCheck, state.setAppVersionCheck], shallow);

    const registerHandler = async (userData) => {
        try {
            const response = await WebAPI.post('users/register', userData);
            let token = null;

            if (!!response.data.code && response.data.code > 0) {
                setToken(null);
                setIsLoggedIn(false);
                showAlert(response.data.message, 'error');
            } else {
                closeAlert();
                setCurrentUser(response.data);
                // if (!!response.data.userAppModuleAccess) {
                //     setAppModules(response.data.userAppModuleAccess);
                // }
                if (!isNullOrUndefined(response.data.userDetails)) {
                    token = response.data.userDetails.accessToken;
                    setToken(token);
                    loadMasterData(token);
                }
            }
            return token;

        } catch (error) {
            showAlert('Internal server error.', 'error');
            console.log(error);
            return '';
        }
    }

    const loadMasterData = async (token) => {
        try {
            var masterDataList = await WebAPI.get('/MasterData/GetMasterDataList', { headers: { "AccessToken": token } });
            setMasterData(masterDataList.data);
        } catch (error) {
            showAlert('Failed to load master data. Internal server error.', 'error');
            console.log(error);
        }
    }
    const loadWarehouseList = async (subscriberdId, token) => {
        try {
            var response = await WebAPI.get('/Warehouse/GetWarehouseList/' + subscriberdId, { headers: { "AccessToken": token } });
            setWarehouseList(response.data);
            setDefaultWarehouseId(response.data.find(w => safeBool(w.isPrimary) === true).id);
        } catch (error) {
            showAlert('Failed to load Warehouse data. Internal server error.', 'error');
            console.log(error);
        }
    }

    const loadUnitConversions = async (token) => {
        try {
            var response = await WebAPI.get('/UOM/UnitConversionList/', { headers: { "AccessToken": token } });
            setUnitConversionList(response.data);
        } catch (error) {
            showAlert('Failed to load UnitConversionList. Internal server error.', 'error');
            console.log(error);
        }
    }

    const loadCustomFields = async (token) => {
        try {
            var response = await WebAPI.get('/GenericTemplate/GenericTemplateList/' + 0, { headers: { "AccessToken": token } });
            if (!isEmptyObjectArray(response.data)) {
                let customFields = response.data.filter(f => safeInt(f.templateTypeId) >= 2002 && safeInt(f.templateTypeId) <= 2014);
                setCustomFields(customFields);
            }
        } catch (error) {
            showAlert('Failed to load GenericTemplateList. Internal server error.', 'error');
            console.log(error);
        }
    }

    const handleUpdateUnitConversionUpdate = (uom, remove = false) => {
        var updatedValues = [...unitConversionList];
        var foundIndex = updatedValues.findIndex(u => u.id === uom.id);
        if (foundIndex > -1) {
            if (remove) {
                updatedValues.splice(foundIndex, 1)
            } else {
                updatedValues[foundIndex] = uom;
            }
        } else {
            updatedValues.push(uom);
        }
        setUnitConversionList(updatedValues);
    }

    const loginHandler = async (email, password) => {
        try {
            const response = await WebAPI.post('Users/Login', { emailAddress: email, password: password, applicationId: config.APPLICATION_ID });
            if (!!response.data.code && response.data.code > 0) {
                setToken(null);
                showAlert(response.data.message, 'error');
            } else {
                setSubscriberSessionDataHandler(response);

                let serverVersion = safeString(response?.data?.appVersion)
                if (!isNullOrEmpty(serverVersion)) {
                    setAppVersion(serverVersion);
                    if (config.ENVIRONMENT === "PROD") {
                        if (config.VERSION !== serverVersion) {
                            setTimeout(() => {
                                setAppVersionCheck(true);
                            }, 500);
                        } else {
                            setAppVersionCheck(false);
                        }
                    }
                }
            }
            if (!isNullOrUndefined(response)) {
                return response.data;
            } else {
                return null;
            }


        } catch (error) {
            showAlert('Internal server error.', 'error');
            console.log(error);
        }
    }

    const refreshCustomFieldsHandler = async () => {
        try {
            var response = await WebAPI.get('/GenericTemplate/GenericTemplateList/' + 0, { headers: { "AccessToken": token } });
            if (!isEmptyObjectArray(response.data)) {
                let customFields = response.data.filter(f => safeInt(f.templateTypeId) >= 2002 && safeInt(f.templateTypeId) <= 2014);
                setCustomFields(customFields);
            }
        } catch (error) {
            showAlert('Failed to load GenericTemplateList. Internal server error.', 'error');
            console.log(error);
        }
    }

    const setSubscriberSessionDataHandler = (response) => {
        closeAlert();
        setCurrentUser(response.data.userDetails);
        if (!!response.data.subscriberDetails) {
            setCurrentSubscriber(response.data.subscriberDetails);
            if (response.data.subscriberDetails.subscriberSettings.isMultiWarehouse) {
                loadWarehouseList(response.data.subscriberDetails.id, response.data.userDetails.accessToken);
            } else {
                if (!isNullOrUndefined(response.data.subscriberDetails.warehouseList[0])) {
                    setDefaultWarehouseId(response.data.subscriberDetails.warehouseList[0].id);
                }
            }
            // Load Unit conversiosn
            loadUnitConversions(response.data.userDetails.accessToken);
            loadCustomFields(response.data.userDetails.accessToken);

            if (!isNullOrUndefined(response.data.subscriberDetails.subscriberSettings)) {
                var settings = response.data.subscriberDetails.subscriberSettings;
                if (!isNullOrEmpty(settings.additionalDetails)) {
                    const additionalDetailData = JSON.parse(settings.additionalDetails);
                    settings.orderSequenceLeadingZeroes = isNullOrUndefined(additionalDetailData.orderSequenceLeadingZeroes) ? 5 : safeInt(additionalDetailData.orderSequenceLeadingZeroes);
                    settings.preventNegativeStock = safeBool(additionalDetailData.preventNegativeStock);
                    settings.allowFreeQuantity = safeBool(additionalDetailData.allowFreeQuantity);
                    settings.includeReferencePrice = safeBool(additionalDetailData.includeReferencePrice);
                    settings.taxInclusivePricing = safeBool(additionalDetailData.taxInclusivePricing);
                    settings.sendAutomaticSMS = safeBool(additionalDetailData.sendAutomaticSMS, true);
                    settings.enableBetaFeatures = safeBool(additionalDetailData.enableBetaFeatures, true);
                    settings.printItemCode = safeBool(additionalDetailData.printItemCode);
                    /*                     settings.autoHideSideMenu = safeBool(additionalDetailData.autoHideSideMenu, true);
                     */
                    settings.showQuantityDescription = safeBool(additionalDetailData.showQuantityDescription);

                    settings.enableSalesPersonModule = safeBool(additionalDetailData.enableSalesPersonModule);
                    settings.enableCess = safeBool(additionalDetailData.enableCess);

                    settings.showOverrides = safeBool(additionalDetailData.showOverrides);
                    settings.deafultInvoiceCashBill = safeBool(additionalDetailData.deafultInvoiceCashBill);
                    settings.enableInvoicePrefixEdit = safeBool(additionalDetailData.enableInvoicePrefixEdit, true);
                    settings.enableQuotationPrefixEdit = safeBool(additionalDetailData.enableQuotationPrefixEdit, false);
                    settings.enableQuotationNumberEdit = safeBool(additionalDetailData.enableQuotationNumberEdit, true);
                    settings.enableSalesOrderPrefixEdit = safeBool(additionalDetailData.enableSalesOrderPrefixEdit, false);
                    settings.enableSalesOrderNumberEdit = safeBool(additionalDetailData.enableSalesOrderNumberEdit, true)
                    settings.printPaymentInfo = safeBool(additionalDetailData.printPaymentInfo);
                    settings.allowToChangeOrderDate = safeBool(additionalDetailData.allowToChangeOrderDate);
                    settings.printTotalPendingAmount = safeBool(additionalDetailData.printTotalPendingAmount);
                    settings.watermarkLabel = safeString(additionalDetailData.watermarkLabel);
                    settings.waterMarkAngle = safeInt(additionalDetailData.waterMarkAngle);
                    settings.invoiceCopyHeading1 = ifNullOrEmpty(additionalDetailData.invoiceCopyHeading1, INVOICE_COPY_HEADINGS[0]);
                    settings.invoiceCopyHeading2 = ifNullOrEmpty(additionalDetailData.invoiceCopyHeading2, INVOICE_COPY_HEADINGS[1]);
                    settings.invoiceCopyHeading3 = ifNullOrEmpty(additionalDetailData.invoiceCopyHeading3, INVOICE_COPY_HEADINGS[2]);
                    settings.invoiceCopyHeading4 = ifNullOrEmpty(additionalDetailData.invoiceCopyHeading4, INVOICE_COPY_HEADINGS[3]);
                    settings.invoiceCopyHeading5 = ifNullOrEmpty(additionalDetailData.invoiceCopyHeading5, INVOICE_COPY_HEADINGS[4]);
                    settings.enableDeliveryChallanPrefixEdit = safeBool(additionalDetailData.enableDeliveryChallanPrefixEdit, true);
                    settings.enableDeliveryChallanNumberEdit = safeBool(additionalDetailData.enableDeliveryChallanNumberEdit, true);
                    settings.AllowTaxRateEdit = ifNullOrEmpty(additionalDetailData.AllowTaxRateEdit, true);
                    settings.validateHSNCode = ifNullOrEmpty(additionalDetailData.validateHSNCode, false);
                    settings.hideSealAndSignature = ifNullOrEmpty(additionalDetailData.hideSealAndSignature, false)
                    settings.HSNCodeSize = ifNullOrEmpty(additionalDetailData.HSNCodeSize, "");
                    settings.defaultCashBillCustomer = safeInt(additionalDetailData.defaultCashBillCustomer);
                    settings.loginDisplayPage = ifNullOrEmpty(additionalDetailData.loginDisplayPage, "");


                    if (!isNullOrUndefined(additionalDetailData.invoiceFormatSettings) && additionalDetailData.invoiceFormatSettings !== "") {
                        settings.invoiceFormatSettings = JSON.parse(additionalDetailData.invoiceFormatSettings);
                    } else {
                        settings.invoiceFormatSettings = null;
                    }

                    if (!isNullOrUndefined(additionalDetailData.quotationFormatSettings) && additionalDetailData.quotationFormatSettings !== "") {
                        settings.quotationFormatSettings = JSON.parse(additionalDetailData.quotationFormatSettings);
                    } else {
                        settings.quotationFormatSettings = null;
                    }

                    if (!isNullOrUndefined(additionalDetailData.invoiceFormatColumnDefination) && additionalDetailData.invoiceFormatColumnDefination !== "") {
                        settings.invoiceFormatColumnDefination = JSON.parse(additionalDetailData.invoiceFormatColumnDefination)
                    } else {
                        settings.invoiceFormatColumnDefination = null;
                    }

                    if (!isNullOrUndefined(additionalDetailData.quotationFormatColumnDefination) && additionalDetailData.quotationFormatColumnDefination !== "") {
                        settings.quotationFormatColumnDefination = JSON.parse(additionalDetailData.quotationFormatColumnDefination)
                    } else {
                        settings.quotationFormatColumnDefination = null;
                    }

                    if (!isNullOrUndefined(additionalDetailData.creditNoteFormatSettings) && additionalDetailData.creditNoteFormatSettings !== "") {
                        settings.creditNoteFormatSettings = JSON.parse(additionalDetailData.creditNoteFormatSettings);
                    } else {
                        settings.creditNoteFormatSettings = null;
                    }


                    if (!isNullOrUndefined(additionalDetailData?.creditNoteFormatColumnDefination) && additionalDetailData.creditNoteFormatColumnDefination !== "") {
                        settings.creditNoteFormatColumnDefination = JSON.parse(additionalDetailData.creditNoteFormatColumnDefination)
                    } else {
                        settings.creditNoteFormatColumnDefination = null;
                    }


                    if (!isNullOrUndefined(additionalDetailData.debitNoteFormatSettings) && additionalDetailData.debitNoteFormatSettings !== "") {
                        settings.debitNoteFormatSettings = JSON.parse(additionalDetailData.debitNoteFormatSettings);
                    } else {
                        settings.debitNoteFormatSettings = null;
                    }


                    if (!isNullOrUndefined(additionalDetailData?.debitNoteFormatColumnDefination) && additionalDetailData.debitNoteFormatColumnDefination !== "") {
                        settings.debitNoteFormatColumnDefination = JSON.parse(additionalDetailData.debitNoteFormatColumnDefination)
                    } else {
                        settings.debitNoteFormatColumnDefination = null;
                    }

                    if (isNullOrUndefined(additionalDetailData.includeTaxSummaryQuotation)) {
                        settings.includeTaxSummaryQuotation = true;
                    } else {
                        settings.includeTaxSummaryQuotation = safeBool(additionalDetailData.includeTaxSummaryQuotation);
                    }

                    if (response.data.subscriberDetails.taxRegistrationTypeId === TAX_REG_COMPOSITION_SCHEME) {
                        settings.invoiceDocumentHeading = ifNullOrEmpty(additionalDetailData.invoiceDocumentHeading, "Bill of Supply")
                    } else {
                        settings.invoiceDocumentHeading = ifNullOrEmpty(additionalDetailData.invoiceDocumentHeading, "TAX INVOICE")
                    }
                    settings.quotationDocumentHeading = ifNullOrEmpty(additionalDetailData.quotationDocumentHeading, "Quotation")

                    settings.includeTaxSummaryDeliveryChallan = safeBool(additionalDetailData.includeTaxSummaryDeliveryChallan);
                    settings.includeAmountDeliveryChallan = safeBool(additionalDetailData.includeAmountDeliveryChallan);
                } else {
                    /*  settings.autoHideSideMenu = true; */
                    settings.enableBetaFeatures = true;
                    settings.enableInvoicePrefixEdit = true;
                    settings.enableQuotationPrefixEdit = true;
                    settings.AllowTaxRateEdit = true;
                }
                if ((safeInt(response.data.subscriberDetails.businessTypeId) === 404 || safeInt(response.data.subscriberDetails.businessTypeId) === 405)) {
                    settings.isPharmacy = true;
                } else {
                    settings.isPharmacy = false;
                }
                setSettings(settings);
            }

        }
        if (!!response.data.userAppModuleAccess) {
            setAppModules(response.data.userAppModuleAccess);
        }
        setToken(response.data.userDetails.accessToken);
        if (!isNullOrUndefined(response.data.userDetails) && !isNullOrUndefined(response.data.userDetails.accessToken)) {
            setIsLoggedIn(true);
        }
        loadMasterData(response.data.userDetails.accessToken);

        getNotificationCount({ token: response.data.userDetails.accessToken }, setNotificationCount);

    }

    const logoutHandler = async () => {
        try {
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(token)
            };
            const apiUrl = config.ENVIRONMENT == "PROD" ? config.SERVER_URL : config.SERVER_URL_LOCAL_HOST;
            const response = await fetch(apiUrl + 'Users/Logout', requestOptions);
            await response.text();
            setIsLoggedIn(false);
            setToken(null);
            setCurrentSubscriber(null);
            setAppModules(null);
        } catch (error) {
            console.log(error);
            setToken(null);
            // showAlert('Internal server error.', 'error');
        }
    }

    const updateSettingsHandler = async (settings) => {
        setSettings(settings);
    }

    const handleUpdateSubscriberDetails = async (userData) => {
        if (!!userData.data.userAppModuleAccess) {
            setAppModules(userData.data.userAppModuleAccess);
        }
        setCurrentUser(userData.data.userDetails);
        if (!!userData.data.subscriberDetails) {
            setCurrentSubscriber(userData.data.subscriberDetails);
            if (!isNullOrUndefined(userData.data.subscriberDetails.warehouseList[0])) {
                setDefaultWarehouseId(userData.data.subscriberDetails.warehouseList[0].id)
            }
            if (!!userData.data.subscriberDetails.subscriberSettings) {
                setSettings(userData.data.subscriberDetails.subscriberSettings);
            }
        }
        setToken(userData.data.userDetails.accessToken);
        if (!isNullOrUndefined(userData.data.userDetails) && !isNullOrUndefined(userData.data.userDetails.accessToken)) {
            setIsLoggedIn(true);
        }
        await loadMasterData(userData.data.userDetails.accessToken);
    }

    const moduleAccess = (appModuleId) => {
        try {
            let appModule = appModules.find(m => m.appModuleId === appModuleId);
            if (!isNullOrUndefined(appModule)) {
                return {
                    allowView: appModule.allowView,
                    allowEdit: appModule.allowEdit,
                    allowAdd: appModule.allowAdd,
                    allowDelete: appModule.allowDelete,
                }
            } else {
                return {
                    allowView: false,
                    allowEdit: false,
                    allowAdd: false,
                    allowDelete: false,
                }
            }
        } catch (error) {
            return {
                allowEdit: false,
                allowAdd: false,
                allowDelete: false,
            }
        }
    }

    const updateContextStatehandler = () => {
        setIsLoggedIn(true);
    }

    const contextValue = {
        token: token,
        isLoggedIn: isLoggedIn,
        register: registerHandler,
        updateContextState: updateContextStatehandler,
        login: loginHandler,
        logout: logoutHandler,
        currentUser: currentUser,
        currentSubscriber: currentSubscriber,
        defaultWarehouseId: defaultWarehouseId,
        settings: settings,
        updateSettings: updateSettingsHandler,
        appModules: appModules,
        masterData: masterData,
        setWarehouseList: setWarehouseList,
        warehouseList: warehouseList,
        unitConversionList: unitConversionList,
        updateUnitConversionList: handleUpdateUnitConversionUpdate,
        moduleAccess: moduleAccess,
        setSubscriberDetails: handleUpdateSubscriberDetails,
        setSubscriberSessionData: setSubscriberSessionDataHandler,
        customFields: customFields,
        refreshCustomFields: refreshCustomFieldsHandler,
        appVersion: appVersion
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    )
};

export default AuthContext;